import React , { useEffect, useState, Suspense } from 'react'
import { Route, Routes , useLocation} from 'react-router-dom'

import Spinner from '../component/Spinner/main';

const Navbar = React.lazy(() => import("../component/Navbar/Main"));
const Footer = React.lazy(() => import("../component/Footer/Main"));
const AboutUs = React.lazy(() => import("../component/AboutUs/Main"));
const Contact = React.lazy(() => import("../component/Contact/Main"));
const HomeDark = React.lazy(() => import("../component/HomeDark/Main"));


const Routing = () => {

    useEffect(() => {
      
      if (localStorage.getItem("navbar") === "darkhome") {
        setfooter({f2 : true})
        setnavbar({n3 : true})
        setbrdcum({b2 : true})
      } else {
        setfooter({f2 : true})
        setnavbar({n3 : true})
        setbrdcum({b2 : true})
      }
    }, [])
    

    const [footer , setfooter] = useState({f2 : true})
    const [navbar , setnavbar] = useState({n2 : true})
    const [brdcum , setbrdcum] = useState({b2 : true})
    
    return (
        <>
          
          <Suspense fallback={<Spinner 
            height="80" 
            width="80" 
            radius="9"
            color="#4fa94d" 
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true} 
            />}>
            {<Navbar navbar = {navbar}/>}
            <Routes>
                <Route path="/" element={<HomeDark setfooter = {setfooter} setnavbar = {setnavbar} setbrdcum = {setbrdcum}/>} />
                <Route path="/pkimanager" element={< AboutUs setnavbar = {setnavbar} brdcum = {brdcum}/>} />
                <Route path="/contact" element={< Contact brdcum = {brdcum}/>} />
            </Routes>
            {<Footer footer = {footer}/>}
          
            
            </Suspense>
        </>
    )
}

export default Routing